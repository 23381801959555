<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.price_lists_laundry_services') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('price_lists_laundry_services.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('price_lists.notes') }}</label>
                <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('price_lists.notes')"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('price_lists_laundry_services.prices') }}</h6>
            <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="form-group">
            <div class="bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th>
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('price_lists_laundry_services.service_name') }}</span>
                    </div>
                  </th>
                  <th>{{ $t('price_lists_laundry_services.service_price') }}</th>
                  <th>{{ $t('price_lists_laundry_services.quantity') }}</th>
                  <th class="text-center">{{ $t("actions") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in data_items" :key="index">

                  <td>
                    <div class="d-flex search-item-group" >

                      <multiselect v-model="it.laundry_service"
                                   :placeholder="$t('price_lists_laundry_services.service_name')"
                                   label="name"
                                   track-by="id"
                                   :options="laundry_services"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>


                    </div>
                  </td>
                  <td>
                    <input type="number" v-model="it.service_price" class="form-control" :placeholder="$t('price_lists_laundry_services.service_price')"/>
                  </td>
                  <td>
                    <input type="number" v-model="it.qty" class="form-control" :placeholder="$t('price_lists_laundry_services.quantity')"/>
                  </td>
                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemForm from "@/view/content/forms/ItemForm";

export default {
  name: "from-services-priceList",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'items/price_lists_laundry_services',
      mainRouteDependency: 'base/dependency',
      laundry_services: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        notes: null,
        is_active: true,


      },
      isEditing: false,
      validation: null,
      repeater_items: {id: null, laundry_service: null, service_price: null, qty: 1},
      data_items: [],
    };
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(this.mainRoute, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/price-lists-laundry-services');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {

      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/price-lists-laundry-services');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.image_url = response.data.data.image_url;
        this.data_items = response.data.data.items;
        if (response.data.data.items.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services = response.data.data;
      });
    },

    addToRepeater() {
      this.data_items.unshift(this.repeater_items);
      this.repeater_items = {id: null, laundry_service: null, service_price: null, qty: 1};
    },
    deleteFromRepeater(index) {
      if (this.data_items.length > 1)
        this.data_items.splice(index, 1);
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.price_lists_laundry_services"), route: '/items/price-lists-laundry-services'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getLaundryServices();
    if (this.idEdit) {
      this.getData();
    } else {
      if (this.data_items.length <= 0) {
        this.addToRepeater();
      }
    }

  },
};
</script>


